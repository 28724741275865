import logo from './logo.svg';
import './App.css';
import { withAuthenticationRequired } from '@auth0/auth0-react';


function App() {
  return (
    // an iframe will be rendered here
      <div className="App">
      {/* <h1>hello</h1> */}

          <iframe src="https://demo.acretech.ai/embedded/public/2eb68c1e-2a32-4bf3-b5b5-53197b4255ab" width="100%" height="100%" frameBorder="0" />
      </div>    
  );
}


export default withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (<div>Redirecting you to the login page...</div>)
});
